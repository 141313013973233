import React from "react";
import {graphql} from 'gatsby';
import { useTranslation } from 'react-i18next'
import { useFilterStoriesByTag } from '@hooks/useFilterStoriesByTag'
import Seo from "@components/Seo";
import Header from "@common/menus/header"
import styled from 'styled-components'
import {AppPageHeader} from '@common';
import SleepListView from '@components/sleep/SleepListView'

const CategoryWrapper = styled.div`
  padding: 0 5% 5% 5%;
  background-color: ${props => props.theme.backgroundColor};
  min-height:100vh;
`

const SleepEpisodeTagTemplate = (props) => {
  const { t } = useTranslation()
  const seoTitle =  t('sleep-tag-template.seoTitle', { tag: props.pageContext.tag })
  const seoDescription = t('sleep-tag-template.seoDescription', { tag: props.pageContext.tag })
  const customQuery  = useFilterStoriesByTag(props.data.allSleepEpisode.edges, props.pageContext.tagSlug, props.pageContext.lang)
  const lang = props.pageContext.lang

  return(
    <>
      <Header location={props.location}/> 
      <Seo description={seoDescription} title={seoTitle}/>
      <AppPageHeader pageTitle={props.pageContext.tag}/>
      <CategoryWrapper>
        {customQuery  && customQuery.map(edge =>(
          <SleepListView
            id={edge.node.id}
            slug={edge.node[lang].slug}
            title={edge.node[lang].title}
            storyCover={edge.node.localImage.coverImage.gatsbyImageData}
            thumbnail={edge.node.localImage.smallImage.gatsbyImageData}
            duration={edge.node[lang].duration}
            voices={edge.node[lang].voices}
            mainFantasy={edge.node.mainTag[lang].tag}
            premiumContent={edge.node[lang].premiumContent}
            key={edge.node.id}
            classicAudioStructure={edge.node[lang].classicAudioStructure}
          />
        ))} 
      </CategoryWrapper >
    </>
  )
 };


export const query = graphql`
  query AllSleepEpisode2($lang: String, $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!){
    allSleepEpisode(filter: {languages: {in: [$lang]}}) {
      edges {
        node {
          id
          imageUrl
          en @include(if: $isEn)  {
            accent {
              accent
            }
            voices{
              id
              name
              gender
            }
            duration
            featured
            premiumContent
            published
            metaDescription
            seoTitle
            seriesEpisodes
            slug
            summary
            title
            classicAudioStructure
          }
          es @include(if: $isEs)  {
            accent {
              accent
            }
            voices{
              id
              name
              gender
            }
            duration
            featured
            premiumContent
            published
            metaDescription
            seoTitle
            seriesEpisodes
            slug
            summary
            title
            classicAudioStructure
          }
          de @include(if: $isDe)  {
            accent {
              accent
            }
            voices{
              id
              name
              gender
            }
            duration
            featured
            premiumContent
            published
            metaDescription
            seoTitle
            seriesEpisodes
            slug
            summary
            title
            classicAudioStructure
          }
          scenario {
            en  @include(if: $isEn) {
              scenario
            }
            es  @include(if: $isEs) {
              scenario
            }
            de  @include(if: $isDe) {
              scenario
            }
          }
          localImage {
            coverImage: childImageSharp {
              gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
            }
          }
          localImage {
            smallImage: childImageSharp {
              gatsbyImageData( placeholder: BLURRED,width: 100, height: 100, layout: CONSTRAINED)
            }
          }
          localImage {
            thumbnail: childImageSharp {
              gatsbyImageData( placeholder: BLURRED,width: 100, height: 100, layout: CONSTRAINED)
            }
          }
          sleepGenre {
            id
            en  @include(if: $isEn) {
              genre
            }
            es  @include(if: $isEs) {
              genre
            }
            de  @include(if: $isDe) {
              genre
            }
            id
          }
          mainTag {
            id
            en  @include(if: $isEn) {
              tag
            }
            es  @include(if: $isEs) {
              tag
            }
            de  @include(if: $isDe) {
              tag
            }
          }
          tags {
            id
            en  @include(if: $isEn) {
              tag
              slug
            }
            es  @include(if: $isEs) {
              tag
              slug
            }
            de  @include(if: $isDe) {
              tag
              slug
            }
          }
        }
      }
    }
  }
`


export default SleepEpisodeTagTemplate;